import { useEffect } from "react";

import { Creative, Founder } from "@/lib/global-types";
import { socket as baseSocket } from "@/lib/socket";

const socket = baseSocket();

export type WebsocketEventOptions = {
  FOUNDER_STATUS_UPDATED: Founder;
  DESIGNER_STATUS_UPDATED: Creative;
};

type WebsocketFunction<T extends keyof WebsocketEventOptions> = (
  data: WebsocketEventOptions[T],
) => void;

export function useWebsocket<T extends keyof WebsocketEventOptions>(
  id: string,
  eventName: T,
  on: WebsocketFunction<T>,
) {
  useEffect(() => {
    socket.auth = {
      token: window.localStorage.getItem("accessToken"),
    };
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    socket.on("connect", () => {});

    return () => {
      socket.off("connect");
    };
  }, [id, on]);

  useEffect(() => {
    socket.on(eventName as string, (data) => {
      if (data.id !== id) return;
      on(data);
    });

    return () => {
      socket.off(eventName as string);
    };
  }, [eventName, id, on]);
}
