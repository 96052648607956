import { useMemo } from "react";

import {
  FeatureContainer,
  FeatureDot,
  FeatureGroup,
} from "@/components/feature-groups";
import { IconPlus } from "@/components/icons/icon-plus";
import { Button, ButtonProps } from "@/components/ui/button";
import {
  cn,
  groupByType,
  PartialBy,
  priceFormatter,
  sizeToMonth,
  sizeToMountCount,
} from "@/lib/utils";

import { ServicesPayload } from "./offer-dialog";

type Props = {
  title: string;
  payload: ServicesPayload;
} & ButtonProps;
function OfferButtonInner({ title, payload, className, ...rest }: Props) {
  const { selectedServices, selectedPlan } = payload;

  const groupedServices = useMemo(() => {
    if (payload.selectedPlan) return groupByType(payload.selectedPlan.services);
    return groupByType(selectedServices);
  }, [payload.selectedPlan, selectedServices]);

  const maximumDuration = useMemo(() => {
    let allServices = selectedServices;

    if (selectedPlan) {
      allServices = selectedPlan.services;
    }

    return allServices.sort(
      (a, b) => sizeToMountCount(b.size) - sizeToMountCount(a.size),
    )[0]?.size;
  }, [selectedPlan, selectedServices]);

  const price = useMemo(() => {
    if (selectedPlan) return selectedPlan.price;
    return selectedServices.reduce(
      // @ts-expect-error TODO: fix when backend is ready
      (acc, service) => acc + (service.price || service.pre_seed_price),
      0,
    );
  }, [selectedPlan, selectedServices]);

  return (
    <Button {...rest} size={"xl"} className={cn("group", className)}>
      <div className="flex flex-col items-center justify-center gap-6">
        <h2>{title}</h2>
        {Object.entries(groupedServices).map(([type, services]) => {
          const counts = sizeToMountCount(services[0].size);
          const dots = [...Array(counts)].map((_, i) => (
            <FeatureDot
              className="group-[.is-active]:border-white group-[.is-active]:bg-white"
              key={i}
            />
          ));
          return (
            <FeatureContainer
              key={type}
              className="group-[.is-active]:border-white group-[.is-active]:after:bg-white"
            >
              <FeatureGroup className="before:group-[.is-active]:bg-white first:before:group-[.is-active]:bg-white last:after:group-[.is-active]:bg-white">
                <span className="uppercase">{type.charAt(0)}</span>
              </FeatureGroup>
              <FeatureGroup className="gap-2 before:group-[.is-active]:bg-white first:before:group-[.is-active]:bg-white last:after:group-[.is-active]:bg-white">
                {dots.length > 0 && dots}
              </FeatureGroup>
            </FeatureContainer>
          );
        })}

        <div className="flex flex-col items-center">
          <span>{sizeToMonth(maximumDuration)}</span>
          <span>{priceFormatter.format(price)}</span>
        </div>
      </div>
    </Button>
  );
}

export const OfferButton = ({
  payload,
  onClick,
  title,
  ...rest
}: PartialBy<Props, "payload">) => {
  if (!payload)
    return (
      <Button
        size={"xl"}
        className="group flex flex-col items-center justify-center gap-6"
        onClick={(e) => {
          onClick?.(e);
        }}
      >
        <h2>{title}</h2>

        <div className="flex items-center justify-center rounded-full border border-black p-5 group-hover:border-white">
          <IconPlus />
        </div>
      </Button>
    );
  return (
    <OfferButtonInner
      title={title}
      payload={payload}
      onClick={onClick}
      {...rest}
    />
  );
};
