import type { AppRouter } from "@op-platform/backend/src/router";
import {
  createTRPCClient,
  CreateTRPCClientOptions,
  createTRPCQueryUtils,
  createTRPCReact,
  httpBatchLink,
} from "@trpc/react-query";
import superjson from "superjson";

import { BACKEND_URL } from "@/lib/constants";
import { queryClient } from "@/lib/query-client";

const getHeaders = () => {
  const cookieStore = window.localStorage;
  const accessToken = cookieStore.getItem("accessToken") || "";

  const commonHeaders = {
    /** Other headers */
  };

  return {
    Authorization: `Bearer ${accessToken}`,
    ...commonHeaders,
  };
};

const trpcOptions: CreateTRPCClientOptions<AppRouter> = {
  links: [
    httpBatchLink({
      url: `${BACKEND_URL}/trpc`,
      transformer: superjson,
      headers: async () => {
        // TODO: add authentication headers
        return {
          ...getHeaders(),
        };
      },
    }),
  ],
};

export const trpc = createTRPCReact<AppRouter>();
export const trpcClient = trpc.createClient(trpcOptions);
export const trpcClientUtils = createTRPCQueryUtils({
  queryClient,
  client: trpcClient,
});

export const trpcVanilla = createTRPCClient<AppRouter>(trpcOptions);
