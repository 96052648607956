import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";
import dayjs from "dayjs";
import { motion } from "framer-motion";

import { AdminService } from "@/api/services/admin.service";
import { BottomNavigation } from "@/components/bottom-navigation";
import { CopyEmailButton } from "@/components/copy-email-button";
import {
  FeatureContainer,
  FeatureDot,
  FeatureGroup,
} from "@/components/feature-groups";
import { FoundersTag } from "@/components/founders-tag";
import { IconBackArrow } from "@/components/icons/icon-back-arrow";
import { Spinner } from "@/components/spinner";
import { Button } from "@/components/ui/button";
import { useTimeZoneClock } from "@/hooks/use-time-zone-clock";
import {
  formatTimezone,
  getServicesFromProposal,
  sizeToMountCount,
} from "@/lib/utils";

export const Route = createFileRoute(
  "/dashboard/admin/founders/$founderId/info",
)({
  loader: async ({ params, context: { queryClient } }) => {
    await Promise.all([
      queryClient.ensureQueryData({
        ...AdminService.fetchFounder(params.founderId),
        staleTime: 0,
      }),
      queryClient.ensureQueryData(
        AdminService.fetchFounderProposal(params.founderId),
      ),
    ]);
  },
  pendingComponent: () => (
    <div className="grid h-full w-full place-items-center">
      <Spinner />
    </div>
  ),
  component: function Page() {
    const { founderId } = Route.useParams();
    const founderQuery = useSuspenseQuery(AdminService.fetchFounder(founderId));
    const founder = founderQuery.data;

    const proposalQuery = useSuspenseQuery(
      AdminService.fetchFounderProposal(founder.id),
    );

    const proposalObject = proposalQuery.data;
    const selectedProposal = proposalObject.proposals.find(
      (p) => p.id === String(founder.selected_proposal_id),
    );

    const proposalServices = getServicesFromProposal(selectedProposal);

    const time = useTimeZoneClock(founder.time_zone!);

    return (
      <div>
        <motion.section
          animate={{ opacity: 1, scale: 1 }}
          initial={{ opacity: 0, scale: 0.99 }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
          className="mx-auto flex max-w-[480px] flex-col items-center gap-20 pb-52"
        >
          <div className="flex flex-col items-center">
            <FoundersTag
              stage={founder.stage}
              services={founder.services}
              plan={founder.plan}
              renderSection={["stage"]}
            />
            <CopyEmailButton
              className="mt-10"
              email={founder.email}
              text={founder.name}
            />

            <span>
              {formatTimezone(founder.time_zone || "")}{" "}
              {dayjs(time).format("HH:mm")}
            </span>
          </div>

          <div className="flex flex-col items-center gap-6">
            <h3>Desired outcome</h3>
            <p className="whitespace-pre-line text-center">
              {founder.project_outcome_description}
            </p>
          </div>

          {founder.links.length > 0 && (
            <div className="flex flex-col items-center gap-6">
              <h3>Links</h3>
              <div className="flex flex-col gap-2">
                {founder.links.map((link: string) => (
                  <a
                    key={link}
                    className="min-w-4 underline"
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {link}
                  </a>
                ))}
              </div>
            </div>
          )}

          {founder.notes && (
            <div className="flex flex-col items-center gap-6">
              <h3>Notes</h3>
              <p className="whitespace-pre-line">{founder.notes}</p>
            </div>
          )}

          {selectedProposal && (
            <div>
              <div className="flex flex-col items-center justify-center gap-6">
                <h2>Founder picked {selectedProposal.type} option</h2>
                {proposalServices?.map((service) => {
                  const counts = sizeToMountCount(proposalServices[0].size);
                  const dots = [...Array(counts)].map((_, i) => (
                    <FeatureDot key={i} />
                  ));
                  return (
                    <FeatureContainer key={service.id}>
                      <FeatureGroup>
                        <span className="uppercase">
                          {service.type.charAt(0)}
                        </span>
                      </FeatureGroup>
                      <FeatureGroup className="gap-2">
                        {dots.length > 0 && dots}
                      </FeatureGroup>
                    </FeatureContainer>
                  );
                })}
              </div>
            </div>
          )}

          {founder.start_date && (
            <div className="flex flex-col items-center">
              <p>Starting day</p>
              <p>{dayjs(founder.start_date).format("DD MMM dddd")}</p>
            </div>
          )}

          {founder.deadline && (
            <div className="flex flex-col items-center">
              <p>Deadline</p>
              <p>{dayjs(founder.deadline).format("DD MMM dddd")}</p>
            </div>
          )}

          {founder.invoice_url && (
            <Link
              to="/dashboard/admin/founders/$founderId/invoice"
              params={{ founderId: founder.id }}
              className="underline"
            >
              View Invoice
            </Link>
          )}
        </motion.section>
        <BottomNavigation
          left={
            <Button size="sm" asChild>
              <Link to="..">
                <IconBackArrow />
              </Link>
            </Button>
          }
          middle={
            <Button size="default" asChild>
              <Link
                to="/dashboard/admin/founders/$founderId/edit"
                params={{ founderId: founder.id }}
              >
                Edit
              </Link>
            </Button>
          }
        />
      </div>
    );
  },
});
