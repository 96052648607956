import { useMutation, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, Link, useRouter } from "@tanstack/react-router";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import { useState } from "react";

import { AdminService } from "@/api/services/admin.service";
import { UpdatesService } from "@/api/services/updates.service";
import { UpdateAction } from "@/components/admin/update-action";
import { BackButton } from "@/components/back-button";
import { BottomNavigation } from "@/components/bottom-navigation";
import { CoundownTimer } from "@/components/countdown-timer";
import { ReceiptViewer } from "@/components/dialogs/founder/receipt-viewer";
import { FadeContainer } from "@/components/fade-container";
import { FoundersTag } from "@/components/founders-tag";
import { IconBackArrow } from "@/components/icons/icon-back-arrow";
import { OPLogo } from "@/components/op-logo";
import { SlideContainer } from "@/components/slide-container";
import { Spinner } from "@/components/spinner";
import { StatusCircle } from "@/components/status-circle";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/solid-dialog";
import { chatState$ } from "@/features/chat/chat.state";
import { useTimeZoneClock } from "@/hooks/use-time-zone-clock";
import { useWebsocket } from "@/hooks/use-websocket";
import { DAYS, IS_DEV } from "@/lib/constants";
import { Founder } from "@/lib/global-types";
import { formatTimezone } from "@/lib/utils";
import { toast } from "@/lib/utils/toast";

export const Route = createFileRoute(
  "/dashboard/admin/founders/$founderId/_$founderId/",
)({
  loader: async ({ params, context: { queryClient } }) => {
    await Promise.all([
      queryClient.ensureQueryData(AdminService.fetchFounder(params.founderId)),
      queryClient.ensureQueryData(
        UpdatesService.getCurrentUpdate(params.founderId),
      ),
      queryClient.ensureQueryData(
        AdminService.fetchFounderBookings(params.founderId),
      ),
    ]);
  },
  pendingComponent: () => (
    <div className="grid h-full w-full place-items-center">
      <Spinner />
    </div>
  ),
  component: function Page() {
    const { founderId } = Route.useParams();
    const [isOverrideDialogOpen, setIsOverrideDialogOpen] = useState(false);
    const [showReceiptViewer, setShowReceiptViewer] = useState(false);
    const { history } = useRouter();

    const founderQuery = useSuspenseQuery(AdminService.fetchFounder(founderId));

    const founder = founderQuery.data;

    const navigate = Route.useNavigate();
    const time = useTimeZoneClock(founder.time_zone!);

    const bookingQuery = useSuspenseQuery(
      AdminService.fetchFounderBookings(founder.id),
    );

    const updatesQuery = useSuspenseQuery(
      UpdatesService.getCurrentUpdate(founder.id),
    );

    const bookings = bookingQuery.data;
    const introCallBooking = bookings.find((b) => b.type === "first_call");
    const kickoffBooking = bookings.find((b) => b.type === "kickoff_call");

    useWebsocket(founder.id, "FOUNDER_STATUS_UPDATED", () => {
      founderQuery.refetch(); // TODO: check if this is necessary
      bookingQuery.refetch();
      updatesQuery.refetch();
    });

    const approveFounderQuery = useMutation({
      mutationFn: async () => {
        await AdminService.approveFounder(founder.id);
        navigate({ to: "." });
      },
    });

    const updateFounderStatusQuery = useMutation({
      mutationFn: async (status: Founder["status"]) => {
        await AdminService.updateFounderStatus(founder.id, status);
        setIsOverrideDialogOpen(false);
        toast("Status updated");
        navigate({ to: "." });
      },
    });

    const approveReceiptMutation = useMutation({
      mutationFn: async () => {
        await AdminService.approveReceipt(founder.id);
        navigate({ to: "." });
      },
    });

    const renderMainContent = () => {
      switch (founder.status) {
        case "onboarded":
          return (
            <Button
              loading={approveFounderQuery.isPending}
              size="lg"
              onClick={() => approveFounderQuery.mutate()}
            >
              Book intro call
            </Button>
          );

        case "rejected":
          if (IS_DEV) {
            return (
              <Button
                size="lg"
                onClick={async () => {
                  await AdminService.DEV_resetToOnboarding(founder.id);
                  navigate({ to: "." });
                }}
              >
                Approve again
              </Button>
            );
          }
          break;

        case "introCallRequestSent":
          return (
            <StatusCircle percentage={25}>Intro call requested</StatusCircle>
          );

        case "introCallBooked": {
          if (!introCallBooking) return;

          const firstCall = dayjs(introCallBooking.date);
          return (
            <CoundownTimer
              startDate={dayjs(introCallBooking.created_at)}
              finishDate={firstCall}
              timeZone={founder.time_zone!}
            >
              First sync on {DAYS[firstCall.day()]} <br />
              {firstCall.format("DD MMM HH:mm")}
            </CoundownTimer>
          );
        }

        case "introCallStarted":
        case "introCallEnded":
          return (
            <div className="flex flex-col items-center gap-10">
              <Button asChild size="lg">
                <Link
                  to="/dashboard/admin/founders/$founderId/proposal"
                  params={{ founderId: founder.id }}
                >
                  Create Options
                </Link>
              </Button>

              <Button asChild>
                <a target="_blank" href="/presentation/founder">
                  Present
                </a>
              </Button>
            </div>
          );

        case "proposalCreated":
        case "proposalSent":
          return (
            <Button asChild size="lg">
              <Link
                to="/dashboard/admin/founders/$founderId/proposal"
                params={{ founderId: founder.id }}
              >
                View Options
              </Link>
            </Button>
          );

        case "companyDetailsSubmitted":
        case "invoiceSent":
        case "agreementAccepted":
        case "proposalConfirmed":
          return (
            <StatusCircle percentage={75}>
              Waiting for
              <br />
              payment
            </StatusCircle>
          );

        case "paymentMade":
          return (
            <div>
              <Button onClick={() => setShowReceiptViewer(true)} size="lg">
                Payment received. <br /> Click to view receipt
              </Button>
              <ReceiptViewer
                url={founder.receipt_url!}
                open={showReceiptViewer}
                onOpenChange={setShowReceiptViewer}
                footer={
                  <Button
                    loading={approveReceiptMutation.isPending}
                    onClick={() => approveReceiptMutation.mutate()}
                  >
                    Confirm
                  </Button>
                }
              />
            </div>
          );

        case "kickoffCallRequestSent":
          return (
            <StatusCircle percentage={90}>Kickoff call requested</StatusCircle>
          );

        case "kickoffCallBooked": {
          if (!kickoffBooking) return;
          const kickoffCallDate = dayjs(kickoffBooking.date);
          return (
            <CoundownTimer
              startDate={dayjs(kickoffBooking.created_at)}
              finishDate={kickoffCallDate}
              timeZone={founder.time_zone!}
            >
              Kickoff call on {DAYS[kickoffCallDate.day()]} <br />
              {kickoffCallDate.format("DD MMM HH:mm")}
            </CoundownTimer>
          );
        }

        case "kickoffCallStarted":
          return (
            <Button
              size="lg"
              /*  onClick={() => updateFounderStatusQuery.mutate("projectStarted")} */
            >
              Kickoff call started <br />
              {/*      Start project! */}
            </Button>
          );

        case "projectStarted":
          return (
            <div className="flex flex-col items-center gap-10">
              <UpdateAction founder={founder} />
            </div>
          );
      }
    };

    return (
      <main>
        <motion.div
          animate={{ opacity: 1, scale: 1 }}
          initial={{ opacity: 0, scale: 0.99 }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
          className="mx-auto flex max-w-[480px] flex-col items-center gap-10 text-center"
        >
          <FoundersTag
            stage={founder.stage}
            services={founder.services}
            plan={founder.plan}
            renderSection={["stage"]}
          />

          <div className="flex flex-col items-center">
            <Link
              to="/dashboard/admin/founders/$founderId/info"
              params={{ founderId: founder.id }}
              className="underline"
            >
              {founder.name}
            </Link>
            <p>{founder.company_name}</p>
            <span>
              {formatTimezone(founder.time_zone || "")}{" "}
              {dayjs(time).format("HH:mm")}
            </span>
          </div>
          <SlideContainer animationKey={founder.status} className="mt-10">
            {renderMainContent()}
          </SlideContainer>
        </motion.div>
        {IS_DEV && (
          <code className="fixed left-5 top-5 flex flex-col items-start text-xs opacity-50">
            <span>email: {founder.email}</span>
            <span>id: {founder.id}</span>
            <span>status: {founder.status}</span>
          </code>
        )}

        <BottomNavigation
          left={<BackButton native />}
          middle={
            <Button
              size="default"
              onClick={() => setIsOverrideDialogOpen(true)}
            >
              Override
            </Button>
          }
          right={
            <Button
              size={"sm"}
              onClick={() => {
                chatState$.targetFounder.set(founder);
                chatState$.dialogOpen.set(true);
              }}
            >
              Chat
            </Button>
          }
        />

        <Dialog open={isOverrideDialogOpen}>
          <DialogContent>
            <div className="no-scrollbar flex max-h-screen w-[100vw] flex-col items-center overflow-y-scroll bg-white pb-40 pt-[104px] md:gap-10 md:pt-[184px]">
              <header className="fixed left-0 right-0 top-6 z-20 flex justify-center text-white mix-blend-difference md:top-10">
                <Link to="/" rel="noreferrer">
                  <OPLogo />
                </Link>
              </header>

              <p className="text-center">
                Select the step that <br /> fits the current status best.
              </p>

              <div className="mt-10 flex flex-col items-center justify-around gap-4 md:flex-row">
                <Button
                  size="lg"
                  onClick={async () => {
                    await updateFounderStatusQuery.mutateAsync(
                      "introCallEnded",
                    );
                    history.back();
                  }}
                >
                  Intro Call Done
                </Button>
                <Button
                  size="lg"
                  onClick={async () => {
                    await updateFounderStatusQuery.mutateAsync(
                      "projectStarted",
                    );
                    history.back();
                  }}
                >
                  Payment Done
                </Button>
                <Button
                  size="lg"
                  onClick={async () => {
                    await updateFounderStatusQuery.mutateAsync("rejected");
                    history.back();
                  }}
                >
                  Cancelled
                </Button>
                {IS_DEV && (
                  <Button
                    size="lg"
                    onClick={async () => {
                      await updateFounderStatusQuery.mutateAsync(
                        "projectStarted",
                      );
                      history.back();
                    }}
                  >
                    Start Project
                  </Button>
                )}
              </div>
            </div>

            <section className="fixed bottom-0 left-0 right-0 z-10 flex items-center justify-center gap-2 pb-5">
              <FadeContainer>
                <Button
                  size="sm"
                  onClick={() => setIsOverrideDialogOpen(false)}
                >
                  <IconBackArrow />
                </Button>
              </FadeContainer>
            </section>
          </DialogContent>
        </Dialog>
      </main>
    );
  },
});
