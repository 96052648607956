import { createFileRoute } from "@tanstack/react-router";

import { trpcClientUtils } from "@/api/trpc";
import { Spinner } from "@/components/spinner";
import { SiteReport } from "@/features/reports/views/site-reports";

export const Route = createFileRoute("/dashboard/admin/site-reports")({
  loader: async () => {
    return {
      stats: await trpcClientUtils.siteReports.getWebsiteStats.ensureData(),
      klavioStats:
        await trpcClientUtils.siteReports.getKlavioReport.ensureData(),
      platformInsights:
        await trpcClientUtils.siteReports.getPlatformInsights.ensureData(),
    };
  },
  pendingComponent: () => (
    <div className="grid h-full w-full place-items-center">
      <Spinner />
    </div>
  ),
  component: SiteReport,
});

export const SiteReportRoute = Route;
