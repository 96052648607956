import * as Sentry from "@sentry/react";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { AxiosError } from "axios";

import { DesignerService } from "@/api/services/designer.service";
import { FounderService } from "@/api/services/founder.service";
import { trpcVanilla } from "@/api/trpc";
import { Spinner } from "@/components/spinner";
import { queryClient } from "@/lib/query-client";
import { toast } from "@/lib/utils/toast";

export const Route = createFileRoute("/api/redirect")({
  validateSearch: (search: Record<string, unknown>) => {
    return {
      token: search.token as string,
      nextPage: search.nextPage as string | undefined,
    };
  },
  beforeLoad: async ({ search: { token, nextPage } }) => {
    if (!token) {
      return redirect({ to: "/" });
    }

    try {
      const ssoData = await trpcVanilla.auth.consumeSSO.mutate({
        token: token,
      });

      if (!ssoData) {
        return redirect({ to: "/" });
      }

      const cookieStore = window.localStorage;

      cookieStore.setItem("accessToken", ssoData.access_token);
      cookieStore.setItem("refreshToken", ssoData.refresh_token);
      cookieStore.setItem("userType", ssoData.user_type);

      switch (ssoData.user_type) {
        case "client": {
          const founder = await queryClient.fetchQuery(
            FounderService.getCurrentFounder(),
          );

          if (!founder.is_onboarding_completed) {
            return redirect({ to: "/onboarding/founder", replace: true });
          } else {
            return redirect({
              to: nextPage || "/dashboard/founder",
              replace: true,
            });
          }
        }

        case "admin":
          return redirect({
            to: "/dashboard/admin",
            search: {
              filter: undefined,
            },
            replace: true,
          });

        case "designer": {
          const designer = await queryClient.fetchQuery(
            DesignerService.getCurrentDesigner(),
          );

          if (!designer.is_onboarding_completed) {
            return redirect({
              to: "/onboarding/creatives/$type",
              params: {
                type: "designer",
              },
              replace: true,
            });
          } else {
            return redirect({
              to: nextPage || "/dashboard/designer",
              replace: true,
            });
          }
        }

        case "engineer":
          throw new Error("Not implemented");
      }
    } catch (error) {
      Sentry.captureException(error);
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          toast("Invalid token. Please try again.");
          return redirect({ to: "/login", replace: true });
        }
      }

      toast("An error occurred. Please try again.");
      return redirect({ to: "/", replace: true });
    }
  },
  pendingComponent: () => (
    <div className="grid h-[100dvh] w-[100dvw] place-items-center">
      <Spinner />
    </div>
  ),
});
