import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";
import { useMemo } from "react";

import { AdminService } from "@/api/services/admin.service";
import { BottomNavigation } from "@/components/bottom-navigation";
import {
  FeatureContainer,
  FeatureDot,
  FeatureGroup,
} from "@/components/feature-groups";
import { IconBackArrow } from "@/components/icons/icon-back-arrow";
import { Button } from "@/components/ui/button";
import {
  capitalize,
  priceFormatter,
  sizeToMountCount,
  timeFormatter,
} from "@/lib/utils";

export const Route = createFileRoute(
  "/dashboard/admin/founders/$founderId/invoice",
)({
  loader: async ({ params, context: { queryClient } }) => {
    await queryClient.ensureQueryData(
      AdminService.fetchFounder(params.founderId),
    );
  },

  component: function Page() {
    const { founderId } = Route.useParams();
    const founderQuery = useSuspenseQuery(AdminService.fetchFounder(founderId));
    const founder = founderQuery.data;

    const allServices = useMemo(() => {
      if (founder.plan) return founder.plan.services;
      else return founder.services;
    }, [founder.plan, founder.services]);

    const price = useMemo(() => {
      if (founder.plan) return founder.plan.price;
      else return allServices.reduce((acc, service) => acc + service.price, 0);
    }, [allServices, founder.plan]);

    const hasPlan = !!founder.plan;

    const maxDurationService = allServices.sort(
      (a, b) => sizeToMountCount(b.size) - sizeToMountCount(a.size),
    )[0];

    const dotCount = sizeToMountCount(maxDurationService.size);

    const dots = Array.from({ length: dotCount }, (_, i) => (
      <FeatureDot key={i} />
    ));

    const servicesText = allServices.map((s) => capitalize(s.type)).join(" + ");

    return (
      <section>
        <div className="mx-auto flex max-w-[320px] flex-col items-center gap-10 pb-52 md:max-w-[420px]">
          <div className="flex flex-col items-center text-center">
            <p># {founder.invoice_number}</p>
            <p>
              Issued {timeFormatter(founder.updated_at).format("DD MMM YYYY")}
            </p>
            <p className="text-red">
              Due{" "}
              {timeFormatter(founder.updated_at)
                .add(14, "days")
                .format("DD MMM YYYY")}
            </p>
          </div>
          <div className="flex flex-col items-center text-center">
            <p>From</p>
            <p>O P U F SOFTWARE HOUSE L.L.C.</p>
            <p>Office # G-01-449, Al Khabeesi, Al Ittihad,</p>
            <p> Dubai, United Arab Emirates</p>
          </div>
          <div className="flex flex-col items-center text-center">
            <p>To</p>
            <p>{founder.billing?.legal_entity_name}</p>
            <p>{founder.billing?.company_address}</p>
            <p>{founder.billing?.country}</p>
            <p>{founder.billing?.company_phone}</p>
          </div>

          <Button
            size={"xl"}
            className="flex cursor-default flex-col items-center gap-10 hover:bg-white hover:text-primary"
          >
            <FeatureContainer>
              {hasPlan
                ? founder.plan?.services.map((service) => {
                    return (
                      <FeatureGroup key={service.id}>
                        {service.type.at(0)?.toUpperCase()}
                      </FeatureGroup>
                    );
                  })
                : allServices.map((service) => {
                    return (
                      <FeatureGroup key={service.id}>
                        {service.type.at(0)?.toUpperCase()}
                      </FeatureGroup>
                    );
                  })}

              {dots && <FeatureGroup className="gap-2">{dots}</FeatureGroup>}
            </FeatureContainer>

            <div>
              <h2>Services</h2>
              <p>{servicesText}</p>
            </div>

            <div>
              <h2>Total</h2>
              <p>{priceFormatter.format(price)}</p>
            </div>
          </Button>

          <div className="flex flex-col items-center text-center">
            <p>Bank details</p>
            <br />
            <p>O P U F SOFTWARE HOUSE L.L.C.</p>
            <p>IBAN: AE920330000019101408346</p>
            <p>MASHREQBANK PSC. DUBAI</p>
            <p>Account: 019101408346</p>
            <p>SWIFT: BOMLAEAD</p>
          </div>
        </div>

        <BottomNavigation
          left={
            <Button size={"sm"} asChild>
              <Link to="..">
                <IconBackArrow />
              </Link>
            </Button>
          }
          middle={
            <Button size={"default"} asChild>
              <a
                href={founder.invoice_url || ""}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download
              </a>
            </Button>
          }
        />
      </section>
    );
  },
});
