import { ComponentProps } from "react";

import {
  FeatureContainer,
  FeatureDot,
  FeatureGroup,
} from "@/components/feature-groups";
import { SERVICE_SIZE_VALUES } from "@/lib/constants";
import { Plan, Service, Stage } from "@/lib/global-types";

type FoundersTagProps = {
  stage?: Stage;
  services?: Service[];
  plan?: Plan | null;
  renderSection?: ("stage" | "services" | "duration" | "plan")[];
} & ComponentProps<typeof FeatureContainer>;

export function FoundersTag({
  stage,
  services,
  plan,
  renderSection = ["stage", "services", "duration", "plan"],
  ...rest
}: FoundersTagProps) {
  const renderStage = () => {
    switch (stage) {
      case "preSeed":
        return (
          <FeatureGroup className="flex items-center justify-between px-2.5">
            <span>0</span>
            <span className="mx-6">→</span>
            <span>1</span>
          </FeatureGroup>
        );
      case "seed":
        return (
          <FeatureGroup className="flex items-center justify-between px-2.5">
            <span>1</span>
            <span className="mx-6">→</span>
            <span>N</span>
          </FeatureGroup>
        );
      case "seriesAplus":
        return (
          <FeatureGroup className="flex items-center justify-between px-2.5">
            <span>N</span>
            <span className="mx-6">→</span>
            <span>XYZ</span>
          </FeatureGroup>
        );
      default:
        return null;
    }
  };

  const renderServices = () => {
    if (plan) return null;
    return services?.map((service) => {
      return (
        <FeatureGroup key={service.id} className="uppercase">
          {service.type.charAt(0)}
        </FeatureGroup>
      );
    });
  };

  const renderDuration = () => {
    const allServices = services;
    if (plan) return;

    const maxDurationService = allServices?.sort(
      (a, b) => SERVICE_SIZE_VALUES[b.size!] - SERVICE_SIZE_VALUES[a.size!],
    )[0];

    if (!maxDurationService?.size) return null;

    const maxMount = SERVICE_SIZE_VALUES[maxDurationService.size];
    const dots = Array.from({ length: maxMount }, (_, i) => (
      <FeatureDot key={i} />
    ));

    return <FeatureGroup className="gap-2">{dots}</FeatureGroup>;
  };

  const renderPlan = () => {
    if (!plan) return null;
    return (
      <FeatureGroup className="flex items-center justify-between">
        <img
          src={`/${plan.name}.png`}
          alt={plan.name}
          className="mx-1 mb-1 size-4 rounded-full"
          width={10}
          height={10}
        />
      </FeatureGroup>
    );
  };

  return (
    <FeatureContainer {...rest}>
      {renderSection.includes("stage") && renderStage()}
      {renderSection.includes("plan") && renderPlan()}
      {renderSection.includes("services") && renderServices()}
      {renderSection.includes("duration") && renderDuration()}
    </FeatureContainer>
  );
}
