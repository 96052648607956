import { ResponsiveBar } from "@nivo/bar";
import { FounderStatus } from "@op-platform/backend/src/entities/clientEntity";
import { DesignerStatus } from "@op-platform/backend/src/entities/designer.entity";
import { useState } from "react";

import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { SiteReportRoute } from "@/routes/dashboard/admin/site-reports";

const statusTranslation: Partial<
  Record<keyof typeof FounderStatus | keyof typeof DesignerStatus, string>
> = {
  onboarded: "Onboarded",
  introCallRequestSent: "Intro Call Request Sent",
  proposalSent: "Proposal Sent",
  proposalConfirmed: "Proposal Confirmed",
  paymentMade: "Payment Made",
  projectStarted: "Project Started",
  rejected: "Rejected",
};

const convertToData = (data: Record<string, number>) => {
  return Object.entries(data).map(([key, value]) => {
    return {
      action: key,
      count: value,
    };
  });
};

export const PlatformReports = () => {
  const { platformInsights } = SiteReportRoute.useLoaderData();

  const clientData = {
    weekly: {
      last: convertToData(platformInsights.week.last.client),
      previous: convertToData(platformInsights.week.previous.client),
    },
    monthly: {
      last: convertToData(platformInsights.month.last.client),
      previous: convertToData(platformInsights.month.previous.client),
    },
  };

  const designerData = {
    weekly: {
      last: convertToData(platformInsights.week.last.designer),
      previous: convertToData(platformInsights.week.previous.designer),
    },
    monthly: {
      last: convertToData(platformInsights.month.last.designer),
      previous: convertToData(platformInsights.month.previous.designer),
    },
  };

  const [clientChartType, setClientChartType] =
    useState<keyof typeof clientData>("weekly");

  const [designerChartType, setDesignerChartType] =
    useState<keyof typeof designerData>("weekly");

  const clientChartData = clientData[clientChartType].last.map((data) => {
    return {
      action: data.action,
      last: data.count,
      previous:
        clientData[clientChartType].previous.find(
          (d) => d.action === data.action,
        )?.count ?? 0,
    };
  });

  const designerChartData = designerData[designerChartType].last.map((data) => {
    return {
      action: data.action,
      last: data.count,
      previous:
        designerData[designerChartType].previous.find(
          (d) => d.action === data.action,
        )?.count ?? 0,
    };
  });

  return (
    <div className="w-full">
      <h3>Platform Status</h3>

      <div className="mt-8 flex w-full flex-col gap-5 md:flex-row">
        <div className="flex-1">
          <h1>Founders</h1>

          <ToggleGroup
            type="single"
            className="mt-8"
            value={clientChartType}
            onValueChange={(value) => {
              if (!value) return;
              setClientChartType(value as "weekly" | "monthly");
            }}
          >
            <ToggleGroupItem size={"medium"} value="weekly">
              Weekly
            </ToggleGroupItem>
            <ToggleGroupItem size={"medium"} value="monthly">
              Monthly
            </ToggleGroupItem>
          </ToggleGroup>

          <div className="h-80">
            <ResponsiveBar
              data={clientChartData}
              keys={["last", "previous"]}
              indexBy={"action"}
              margin={{ top: 50, bottom: 50 }}
              axisLeft={null}
              axisBottom={{
                format: (value) =>
                  statusTranslation[value as keyof typeof statusTranslation],

                tickRotation: 10,
                legendPosition: "start",
              }}
              theme={{
                text: {
                  fill: "#ffffff",
                },
                axis: {
                  ticks: {
                    text: {
                      fill: "#111111",
                    },
                  },
                },
                legends: {
                  text: {
                    fill: "#111111",
                  },
                },
              }}
              colors={{
                scheme: "category10",
              }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "top-right",
                  direction: "column",
                  itemHeight: 20,
                  itemWidth: 20,
                  translateX: -75,
                  translateY: -30,
                },
              ]}
              legendLabel={({ id }) => {
                switch (id) {
                  case "last":
                    return "Current";
                  case "previous":
                    return "Previous";
                  default:
                    return "";
                }
              }}
              tooltip={({ data }) => {
                return (
                  <div className="rounded-md bg-white p-2 shadow-md">
                    <h4 className="font-bold">
                      {
                        statusTranslation[
                          data.action as keyof typeof statusTranslation
                        ]
                      }
                    </h4>
                  </div>
                );
              }}
            />
          </div>
        </div>

        <div className="flex-1">
          <h1>Designers</h1>

          <ToggleGroup
            type="single"
            className="mt-8"
            value={designerChartType}
            onValueChange={(value) => {
              if (!value) return;
              setDesignerChartType(value as "weekly" | "monthly");
            }}
          >
            <ToggleGroupItem size={"medium"} value="weekly">
              Weekly
            </ToggleGroupItem>
            <ToggleGroupItem size={"medium"} value="monthly">
              Monthly
            </ToggleGroupItem>
          </ToggleGroup>

          <div className="h-80">
            <ResponsiveBar
              data={designerChartData}
              indexBy={"action"}
              keys={["last", "previous"]}
              margin={{ top: 50, bottom: 50 }}
              axisLeft={null}
              axisBottom={{
                format: (value) =>
                  statusTranslation[value as keyof typeof FounderStatus],
              }}
              theme={{
                text: {
                  fill: "#ffffff",
                },
                axis: {
                  ticks: {
                    text: {
                      fill: "#111111",
                    },
                  },
                },
                legends: {
                  text: {
                    fill: "#111111",
                  },
                },
              }}
              colors={{
                scheme: "category10",
              }}
              colorBy="id"
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "top-right",
                  direction: "column",
                  itemHeight: 20,
                  itemWidth: 80,
                  translateX: -20,
                  translateY: -30,
                },
              ]}
              legendLabel={({ id }) => {
                switch (id) {
                  case "last":
                    return "Current";
                  case "previous":
                    return "Previous";
                  default:
                    return "";
                }
              }}
              tooltip={({ data }) => {
                return (
                  <div className="rounded-md bg-white p-2 shadow-md">
                    <h4 className="font-bold">
                      {
                        statusTranslation[
                          data.action as keyof typeof FounderStatus
                        ]
                      }
                    </h4>
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
