import { createFileRoute, redirect } from "@tanstack/react-router";
import { useState } from "react";

import { BackButton } from "@/components/back-button";
import { BottomNavigation } from "@/components/bottom-navigation";
import { Links } from "@/components/links";
import { Button } from "@/components/ui/button";
import {
  founderOnboardingState$,
  isFounderOnboardingCompleted$,
} from "@/store/founder.state";

export const Route = createFileRoute("/onboarding/founder/links")({
  beforeLoad: () => {
    if (!isFounderOnboardingCompleted$.peek()) {
      return redirect({ to: "/onboarding/founder" });
    }
  },
  component: function Page() {
    const links = founderOnboardingState$.links.get();
    const [currentLink, setCurrentLink] = useState("");

    const navigate = Route.useNavigate();

    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();

      navigate({
        to: "/onboarding/founder/mail-preference",
      });
    };

    return (
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center text-center"
      >
        <h1>
          {"Any links you can share with us?"} <br />
          {"Website, social or maybe a figma file?"}
        </h1>

        <Links
          className="mt-20"
          currentLink={currentLink}
          setCurrentLink={setCurrentLink}
          linkList={links}
          setLinkList={(val) => {
            founderOnboardingState$.links.set(val as string[]);
          }}
        />

        <BottomNavigation
          left={<BackButton native />}
          middle={<Button type="submit">Next</Button>}
        />
      </form>
    );
  },
});
