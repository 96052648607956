import { createFileRoute, redirect } from "@tanstack/react-router";

import { BackButton } from "@/components/back-button";
import { BottomNavigation } from "@/components/bottom-navigation";
import { TimezoneSelector } from "@/components/timezone-selector";
import { Button } from "@/components/ui/button";
import {
  founderOnboardingState$,
  isFounderOnboardingCompleted$,
} from "@/store/founder.state";

export const Route = createFileRoute("/onboarding/founder/timezone")({
  beforeLoad: () => {
    if (!isFounderOnboardingCompleted$.peek()) {
      return redirect({ to: "/onboarding/founder" });
    }
  },
  component: function Page() {
    const timeZone = founderOnboardingState$.time_zone.get();
    const timeZoneLabel = founderOnboardingState$.timezone_label.get();

    const navigate = Route.useNavigate();

    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();

      navigate({
        to: "/onboarding/founder/company",
      });
    };

    return (
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center text-center"
      >
        <h1>
          {`Good to meet you, ${name}`} <br />
          {"Where are you located?"}
        </h1>

        <div className="mt-10 md:mt-20">
          <TimezoneSelector
            defaultValue={timeZoneLabel}
            onChange={(timezone, city) => {
              founderOnboardingState$.time_zone.set(timezone);
              founderOnboardingState$.timezone_label.set(city);
            }}
          />
        </div>

        <BottomNavigation
          left={<BackButton native />}
          middle={timeZone && <Button type="submit">Next</Button>}
        />
      </form>
    );
  },
});
