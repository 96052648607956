import slugify from "slugify";
import { Channel, StreamChat, UserResponse } from "stream-chat";

import { get, post } from "@/api/client";
import { SSOTokens } from "@/api/services/auth.service";
import { FounderService } from "@/api/services/founder.service";
import { Founder } from "@/lib/global-types";
import { queryClient } from "@/lib/query-client";

export const chatClient = StreamChat.getInstance("5atwbpuj6b97");

const cookieStore = window.localStorage;

const userType = cookieStore.getItem("userType") as
  | SSOTokens["user_type"]
  | undefined;

const getId = (client: UserResponse) =>
  `founder-${slugify(`${client.name}-${client.id}`)}`;
const ADMIN_ID = "op_admin";

class ChatService {
  _user: UserResponse | null = null;
  _channel: Channel | null = null;
  connected = false;

  get user() {
    if (!this._user) {
      throw new Error("User not found");
    }
    return this._user;
  }

  get userType() {
    return userType;
  }

  get channel() {
    if (!this._channel) {
      throw new Error("Channel not found");
    }
    return this._channel;
  }

  async init() {
    this._user = await ChatService.getUser();
  }

  static async getUser(): Promise<UserResponse | null> {
    switch (userType) {
      case "client": {
        const founder = await queryClient.fetchQuery(
          FounderService.getCurrentFounder(),
        );

        return {
          id: founder.id,
          name: founder.name,
          image:
            "https://ca.slack-edge.com/T024FLACD-U024L1QDY-9b2ecdfe41ce-512",
        };
      }

      case "admin":
        return Promise.resolve({
          id: ADMIN_ID,
          name: "OP Admin",
        });

      default:
        return null;
    }
  }

  async connectUser() {
    if (this.connected) return;
    const { token } = await get<{ token: string }>("/chat/token");

    await chatClient.connectUser(this.user, token);
    this.connected = true;
  }

  async joinChannelAsFounder() {
    await post("/chat/create", {});

    const channel = await chatClient.channel("messaging", getId(this.user));

    this._channel = channel;
    await this._channel.watch();

    return channel;
  }

  async joinChannelAsAdmin(founder: Founder) {
    const user: UserResponse = {
      id: founder.id,
      name: founder.name,
    };

    await post("/chat/create-admin", {
      id: founder.user_id,
    });

    const channel = await chatClient.channel("messaging", getId(user));

    this._channel = channel;
    await this._channel.watch();

    return channel;
  }

  async disconnectUser() {
    await chatClient.disconnectUser();

    this._user = null;
    this._channel = null;
    this.connected = false;
  }
}

export const chatService = new ChatService();
